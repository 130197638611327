/*
	Author: Nicdark
	Author URI: http://www.nicdarkthemes.com/
*/


/* 

1 THEME STRUCTURE

	1.1 body

	1.2 container

	1.3 grid

2 TEXT

	2.1 text rules

	2.2 text color

	2.3 text size

	2.4 text font

3 FORMS

4 TABLE

5 TABS

6 CALENDAR

7 FRAMEWORK CLASS 

	7.1 text-align

	7.2 text-transform

	7.3 color

	7.4 font-size

	7.5 height

	7.6 line-height

	7.7 width

	7.8 background-color

	7.9 background

	7.10 filter

	7.11 margin

	7.12 padding

	7.13 border

	7.14 box-sizing

	7.15 list-style

	7.16 display

	7.17 vertical-align

	7.18 float

	7.19 bg size position repeat

	7.20 position

	7.21 right left top bottom

	7.22 overflow

	7.23 cursor

	7.24 outline


8 CUSTOM FOR THEME

	8.1 navigation 1

	8.2 navigation 2

	8.3 navigation 3

	8.4 navigation top header 1

	8.5 navigation top header 2

	8.6 navigation copyright


9 TYPED PLUGIN


10 JQUERY UI


11 RESPONSIVE

	11.1 ipad land

	11.2 ipad port

	11.3 iphone land

	11.4 iphone port

	11.5 all responsive

	11.6 all iphone

*/


/* 1 THEME STRUCTURE ------------------------------------------------------- */

/* 1.1 body */
body{ margin:0px; padding:0px; }

/* 1.2 container */
.nicdark_container{ width:1200px; margin:auto; padding: 0px; }
.nicdark_clearfix:after { content: ""; display: block; height: 0; clear: both; visibility: hidden; }
.nicdark_site{ float: left; width: 100%; -webkit-transition: all 0.8s ease; -moz-transition: all 0.8s ease; -o-transition: all 0.8s ease; -ms-transition: all 0.8s ease; transition: all 0.8s ease; }
.nicdark_section{ width: 100%; float: left; }

/* 1.3 grid */
.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12{ padding:15px; float:left; display:inline; box-sizing:border-box; }
.grid_1 {width:8.33%;}
.grid_2 {width:16.66%;}
.grid_3 {width:25%;}
.grid_4 {width:33.33%;}
.grid_5 {width:41.66%;}
.grid_6 {width:50%;}
.grid_7 {width:58.33%;}
.grid_8 {width:66.66%;}
.grid_9 {width:75%;}
.grid_10 {width:83.33%;}
.grid_11 {width:91.66%;}
.grid_12 {width:100%;}

/* 2 TEXT ------------------------------------------------------- */

/* 2.1 text rules */
p,h1,h2,h3,h4,h5,h6{ margin:0px; padding:0px; font-weight: normal; }
a{ text-decoration: none; }

/* 2.2 text color */
h1,h2,h3,h4,h5,h6,.nicdark_datepicker .ui-datepicker-header .ui-datepicker-title{ color: #07034f; }
p,input,label,textarea,.nicdark_datepicker{ color:#a3a3a3; }
a{ color: #a3a3a3; }

/* 2.3 text size */
h1{ font-size: 30px; line-height: 30px; }
h2{ font-size: 25px; line-height: 25px; }
h3{ font-size: 20px; line-height: 20px; }
h4{ font-size: 17px; line-height: 17px; }
h5{ font-size: 15px; line-height: 15px; }
h6{ font-size: 13px; line-height: 13px; }
p { font-size: 16px; line-height: 30px; }
input,label,textarea{ font-size: 15px; }

/* 2.4 text font */
h1,h2,h3,h4,h5,h6,.nicdark_first_font,.nicdark_datepicker .ui-datepicker-title{ font-family: 'Montserrat', sans-serif; }
p,a,input,label,.nicdark_second_font,.nicdark_datepicker,textarea{ font-family: 'Varela Round', sans-serif; }
.nicdark_third_font{ font-family: 'Hind', sans-serif; }


/* 3 FORMS ------------------------------------------------------- */

input,textarea { background-color: #f9f9f9; outline: 0; border: 1px solid #f1f1f1; padding: 10px 20px; float: left; width: 100%; box-sizing:border-box; }

/* 4 TABLE ------------------------------------------------------- */

table{ border-collapse: collapse; }


/* 5 TABS ------------------------------------------------------- */

.nicdark_tabs .ui-tabs-active.ui-state-active { box-shadow: 0px 2px 0px #68B78C; }


/* 6 CALENDAR ------------------------------------------------------- */

.nicdark_datepicker { float: left; width: 100%; text-align: center; }
.nicdark_datepicker .ui-datepicker { float: left; width: 100%; }
.nicdark_datepicker .ui-datepicker-header { float: left; width: 100%; }
.nicdark_datepicker .ui-datepicker-calendar { display: inline-table; width: 95%; margin-top: 15px; margin-bottom: 15px; }
.nicdark_datepicker .ui-datepicker-prev { display: none; }
.nicdark_datepicker .ui-datepicker-next { display: none; }

.nicdark_datepicker .ui-datepicker-header .ui-datepicker-title { background-color: #F9F9F9; padding: 20px; font-size: 20px; font-weight: bolder; border-bottom: 1px solid #f1f1f1; }
.nicdark_datepicker .ui-datepicker-calendar { margin-top: 10px; }
.nicdark_datepicker .ui-datepicker-calendar th { color: #07034f; }
.nicdark_datepicker .ui-datepicker-calendar th,.nicdark_datepicker .ui-datepicker-calendar td { padding: 10px 5px; }

.nicdark_datepicker .ui-datepicker-unselectable span { background-color: #fff; }
.nicdark_datepicker a.ui-state-default { background-color: #68B78C; color:#fff; padding: 5px; border-radius: 3px; }

/* 7 FRAMEWORK CLASS ------------------------------------------------------- */


/* 7.1 text-align */
.nicdark_text_align_center { text-align: center; }
.nicdark_text_align_right { text-align: right; }
.nicdark_text_align_left { text-align: left; }

/* 7.2 text-transform */
.nicdark_text_transform_uppercase{ text-transform: uppercase; }


/* 7.3 color */
.nicdark_color_white { color: #fff; }
.nicdark_color_grey { color: #a3a3a3; }
.nicdark_color_greydark { color: #07034f; }
.nicdark_color_greydark_hover:hover { color: #07034f; }
.nicdark_color_f1f1f1{ color: #f1f1f1; }
.nicdark_color_green{ color: #68B78C; }
.nicdark_color_green_hover:hover{ color: #68B78C; }
.nicdark_color_orange{ color: #07034f; }
.nicdark_color_orange_hover:hover{ color: #07034f; }


/*placeholer*/
.nicdark_placeholder_color_white::-webkit-input-placeholder { color: #fff; }
.nicdark_placeholder_color_white::-moz-placeholder { color: #fff; }
.nicdark_placeholder_color_white:-ms-input-placeholder { color: #fff; }
.nicdark_placeholder_color_white:-moz-placeholder { color: #fff; }



/* 7.4 font-size */
.nicdark_font_size_80 { font-size: 80px; line-height: 80px; }
.nicdark_font_size_70 { font-size: 70px; line-height: 70px; }
.nicdark_font_size_60 { font-size: 60px; line-height: 60px; }
.nicdark_font_size_50 { font-size: 50px; line-height: 50px; }
.nicdark_font_size_40 { font-size: 40px; line-height: 45px; }
.nicdark_font_size_30 { font-size: 30px; line-height: 30px; }
.nicdark_font_size_25 { font-size: 25px; line-height: 25px; }
.nicdark_font_size_20 { font-size: 20px; line-height: 20px; }
.nicdark_font_size_15 { font-size: 15px; line-height: 15px; }
.nicdark_font_size_14 { font-size: 14px; line-height: 14px; }
.nicdark_font_size_13 { font-size: 13px; line-height: 13px; }
.nicdark_font_size_12 { font-size: 12px; line-height: 12px; }
.nicdark_font_size_11 { font-size: 11px; line-height: 12px; }
.nicdark_font_size_10 { font-size: 10px; line-height: 10px; }
.nicdark_font_size_8 { font-size: 8px; line-height: 8px; }


/* 7.5 height */
.nicdark_height_1000{ height: 1000px; }
.nicdark_height_600{ height: 600px; }
.nicdark_height_570{ height: 570px; }
.nicdark_height_550{ height: 550px; }
.nicdark_height_500{ height: 500px; }
.nicdark_height_450{ height: 450px; }
.nicdark_height_400{ height: 400px; }
.nicdark_height_300{ height: 300px; }
.nicdark_height_250{ height: 250px; }
.nicdark_height_200{ height: 200px; }
.nicdark_height_150{ height: 150px; }
.nicdark_height_100{ height: 100px; }
.nicdark_height_90{ height: 90px; }
.nicdark_height_80{ height: 80px; }
.nicdark_height_70{ height: 70px; }
.nicdark_height_60{ height: 60px; }
.nicdark_height_55{ height: 55px; }
.nicdark_height_50{ height: 50px; }
.nicdark_height_40{ height: 40px; }
.nicdark_height_33{ height: 33px; }
.nicdark_height_30{ height: 30px; }
.nicdark_height_25{ height: 25px; }
.nicdark_height_20{ height: 20px; }
.nicdark_height_15{ height: 15px; }
.nicdark_height_13{ height: 13px; }
.nicdark_height_10{ height: 10px; }
.nicdark_height_5{ height: 5px; }
.nicdark_height_3{ height: 3px; }
.nicdark_height_2{ height: 2px; }
.nicdark_height_1{ height: 1px; }
.nicdark_height_auto{ height: auto; }
.nicdark_height_100_percentage { height: 100%; }


/* 7.6 line-height */
.nicdark_line_height_5 { line-height: 5px; }
.nicdark_line_height_16 { line-height: 16px; }
.nicdark_line_height_17 { line-height: 17px; }
.nicdark_line_height_18 { line-height: 18px; }
.nicdark_line_height_20 { line-height: 20px; }
.nicdark_line_height_25 { line-height: 25px; }
.nicdark_line_height_31 { line-height: 31px; }
.nicdark_line_height_30 { line-height: 30px; }
.nicdark_line_height_35 { line-height: 35px; }
.nicdark_line_height_50 { line-height: 50px; }


/* 7.7 width */
.nicdark_width_700 { width: 700px; }
.nicdark_width_300 { width: 300px; }
.nicdark_width_200 { width: 200px; }
.nicdark_width_170 { width: 170px; }
.nicdark_width_150 { width: 150px; }
.nicdark_width_100 { width: 100px; }
.nicdark_width_90 { width: 90px; }
.nicdark_width_70 { width: 70px; }
.nicdark_width_60 { width: 60px; }
.nicdark_width_55 { width: 55px; }
.nicdark_width_33 { width: 33px; }
.nicdark_width_30_hover:hover { width: 30px; }
.nicdark_width_25 { width: 25px; }
.nicdark_width_5 { width: 5px; }
.nicdark_width_100_percentage { width: 100%; }
.nicdark_width_80_percentage { width: 80%; }
.nicdark_width_75_percentage { width: 75%; }
.nicdark_width_70_percentage { width: 70%; }
.nicdark_width_66_percentage { width: 66.66%; }
.nicdark_width_65_percentage { width: 65%; }
.nicdark_width_60_percentage { width: 60%; }
.nicdark_width_55_percentage { width: 55%; }
.nicdark_width_50_percentage { width: 50%; }
.nicdark_width_35_percentage { width: 35%; }
.nicdark_width_33_percentage { width: 33.33%; }
.nicdark_width_40_percentage { width: 40%; }
.nicdark_width_30_percentage { width: 30%; }
.nicdark_width_25_percentage { width: 25%; }
.nicdark_width_20_percentage { width: 20%; }
.nicdark_width_15_percentage { width: 15%; }
.nicdark_width_10_percentage { width: 10%; }
.nicdark_width_5_percentage { width: 5%; }


/* 7.8 background-color */
.nicdark_bg_white { background-color: #ffffff; }
.nicdark_bg_white_hover:hover { background-color: #ffffff; }
.nicdark_bg_blue { background-color: #8AC6D0; }
.nicdark_bg_green { background-color: #68B78C; }
.nicdark_bg_green_hover:hover { background-color: #68B78C; }
.nicdark_bg_grey { background-color: #f9f9f9; }
.nicdark_bg_grey_2 { background-color: #a3a3a3; }
.nicdark_bg_grey_3 { background-color: #e4e4e4; }
.nicdark_bg_greydark { background-color: #444444; }
.nicdark_bg_greydark_hover:hover { background-color: #444444; }
.nicdark_bg_orange { background-color: #07034f; }
.nicdark_bg_red { background-color: #CC8585; }
.nicdark_bg_yellow { background-color: #FFDA44; }
.nicdark_bg_orangedark { background-color: #c4aa87; } 
.nicdark_bg_blue_alpha { background-color: rgba(138, 198, 208, 0.75); }
.nicdark_bg_green_alpha { background-color: rgba(112, 193, 144, 0.95); }
.nicdark_bg_greydark_alpha { background-color: rgba(101, 100, 96, 0.3); }
.nicdark_bg_greydark_alpha_4 { background-color: rgba(101, 100, 96, 0.4); }
.nicdark_bg_greydark_alpha_6 { background-color: rgba(101, 100, 96, 0.6); }
.nicdark_bg_greydark_alpha_8 { background-color: rgba(101, 100, 96, 0.8); }
.nicdark_bg_greydark_alpha_9 { background-color: rgba(101, 100, 96, 0.9); }


/* 7.9 background */
.nicdark_background_none{ background: none; }
.nicdark_background_none_hover:hover{ background: none; }
.nicdark_bg_greydark_alpha_gradient { 
	background: -moz-linear-gradient(top, rgba(101,100,96,0) 0%, rgba(101,100,96,0.1) 60%, rgba(101,100,96,0.65) 100%);
	background: -webkit-linear-gradient(top, rgba(101,100,96,0) 0%,rgba(101,100,96,0.1) 60%,rgba(101,100,96,0.65) 100%);
	background: linear-gradient(to bottom, rgba(101,100,96,0) 0%,rgba(101,100,96,0.1) 60%,rgba(101,100,96,0.65) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00656460', endColorstr='#a6656460',GradientType=0 );
}
.nicdark_bg_greydark_alpha_gradient_2 { 
	background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0.45) 0%,rgba(101,100,96,0) 35%,rgba(101,100,96,0) 45%,rgba(0, 0, 0, 0.6) 100%);
	background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.45) 0%,rgba(101,100,96,0) 35%,rgba(101,100,96,0) 45%,rgba(0, 0, 0, 0.6) 100%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.45) 0%,rgba(101,100,96,0) 35%,rgba(101,100,96,0) 45%,rgba(0, 0, 0, 0.6) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99656460', endColorstr='#99656460',GradientType=0 );
}
.nicdark_bg_greydark_alpha_gradient_3 { 
	background: -moz-linear-gradient(top, rgba(101,100,96,0) 0%, rgba(101,100,96,0.65) 100%);
	background: -webkit-linear-gradient(top, rgba(101,100,96,0) 0%,rgba(101,100,96,0.65) 100%);
	background: linear-gradient(to bottom, rgba(101,100,96,0) 0%,rgba(101,100,96,0.65) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00656460', endColorstr='#a6656460',GradientType=0 );
}
.nicdark_bg_greydark_alpha_gradient_4 { 
	background: -moz-linear-gradient(top, rgba(101,100,96,0.65) 0%, rgba(101,100,96,0.1) 60%, rgba(101,100,96,0) 100%);
	background: -webkit-linear-gradient(top, rgba(101,100,96,0.65) 0%,rgba(101,100,96,0.1) 60%,rgba(101,100,96,0) 100%);
	background: linear-gradient(to bottom, rgba(101,100,96,0.65) 0%,rgba(101,100,96,0.1) 60%,rgba(101,100,96,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00656460', endColorstr='#a6656460',GradientType=0 );
}
.nicdark_bg_greydark_alpha_gradient_5 { 
	background: -moz-linear-gradient(top, rgba(101,100,96,0.6) 0%, rgba(101,100,96,0.6) 75%, rgba(101,100,96,0) 100%);
	background: -webkit-linear-gradient(top, rgba(101,100,96,0.6) 0%,rgba(101,100,96,0.6) 75%,rgba(101,100,96,0) 100%);
	background: linear-gradient(to bottom, rgba(101,100,96,0.6) 0%,rgba(101,100,96,0.6) 75%,rgba(101,100,96,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99656460', endColorstr='#00656460',GradientType=0 );
}
.nicdark_bg_gradient_red_to_green{

	background: rgb(204,133,133);
	background: -moz-linear-gradient(left, rgba(204,133,133,1) 0%, rgba(204,133,133,1) 61%, rgba(104,183,140,1) 82%, rgba(104,183,140,1) 100%);
	background: -webkit-linear-gradient(left, rgba(204,133,133,1) 0%,rgba(204,133,133,1) 61%,rgba(104,183,140,1) 82%,rgba(104,183,140,1) 100%);
	background: linear-gradient(to right, rgba(204,133,133,1) 0%,rgba(204,133,133,1) 61%,rgba(104,183,140,1) 82%,rgba(104,183,140,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc8585', endColorstr='#68b78c',GradientType=1 );

}

/* 7.10 filter */
.nicdark_filter_blur_5 { filter:blur(5px); -webkit-filter: blur(5px); }


/* 7.11 margin */
/*margin general*/
.nicdark_margin_0 { margin: 0px; }
.nicdark_margin_5 { margin: 5px; }
.nicdark_margin_10 { margin: 10px; }
.nicdark_margin_15 { margin: 15px; }
.nicdark_margin_20 { margin: 20px; }
/*margin bottom*/
.nicdark_margin_bottom_20 { margin-bottom: 20px; }
.nicdark_margin_bottom_10 { margin-bottom: 10px; }
/*margin top*/
.nicdark_margin_top_20 { margin-top: 20px; }
.nicdark_margin_top_13 { margin-top: 13px; }
.nicdark_margin_top_10 { margin-top: 10px; }
.nicdark_margin_top_5 { margin-top: 5px; }
.nicdark_margin_top_2 { margin-top: 2px; }
/*margin right*/
.nicdark_margin_right_5 { margin-right: 5px; }
.nicdark_margin_right_10 { margin-right: 10px; }
.nicdark_margin_right_20 { margin-right: 20px; }
.nicdark_margin_right_40 { margin-right: 40px; }
.nicdark_margin_right_50 { margin-right: 50px; }
.nicdark_margin_right_60 { margin-right: 60px; }
/*margin left*/
.nicdark_margin_left_0 { margin-left: 0px; }
.nicdark_margin_left_5 { margin-left: 5px; }
.nicdark_margin_left_10 { margin-left: 10px; }
.nicdark_margin_left_15 { margin-left: 15px; }
.nicdark_margin_left_20 { margin-left: 20px; }
.nicdark_margin_left_40 { margin-left: 40px; }
.nicdark_margin_left_80 { margin-left: 80px; }
.nicdark_margin_left_60 { margin-left: 60px; }
.nicdark_margin_left_70 { margin-left: 70px; }
.nicdark_margin_left_120 { margin-left: 120px; }
.nicdark_margin_left_200 { margin-left: 200px; }
.nicdark_margin_left_220 { margin-left: 220px; }
.nicdark_margin_left_240 { margin-left: 240px; }
/*margin left negative*/
.nicdark_margin_left_negative_15 { margin-left: -15px; }
/*margin bottom negative*/
.nicdark_margin_bottom_negative_15 { margin-bottom: -15px; }
.nicdark_margin_bottom_100_negative { margin-bottom: -100px; }
.nicdark_margin_bottom_105_negative { margin-bottom: -105px; }
/*margin top negative*/
.nicdark_margin_top_negative_30 { margin-top: -30px; }
.nicdark_margin_top_negative_225 { margin-top: -225px; }
.nicdark_margin_top_negative_300 { margin-top: -300px; }


/* 7.12 padding */
/*padding_general*/
.nicdark_padding_0 { padding: 0px; }
.nicdark_padding_5 { padding: 5px; }
.nicdark_padding_5_10 { padding: 5px 10px; }
.nicdark_padding_8 { padding: 8px; }
.nicdark_padding_10 { padding: 10px; }
.nicdark_padding_10_20 { padding: 10px 20px; }
.nicdark_padding_20_10 { padding: 20px 10px; }
.nicdark_padding_13_10 { padding: 13px 10px; }
.nicdark_padding_13 { padding: 13px; }
.nicdark_padding_15 { padding: 15px; }
.nicdark_padding_20 { padding: 20px; }
.nicdark_padding_28 { padding: 28px; }
.nicdark_padding_20_50 { padding: 20px 50px; }
.nicdark_padding_20_25 { padding: 20px 25px; }
.nicdark_padding_30 { padding: 30px; }
.nicdark_padding_40 { padding: 40px; }
.nicdark_padding_40_20 { padding: 40px 20px; }
.nicdark_padding_50 { padding: 50px; }
.nicdark_padding_60 { padding: 60px; }
.nicdark_padding_80 { padding: 80px; }
.nicdark_padding_90 { padding: 90px; }
.nicdark_padding_100 { padding: 100px; }
.nicdark_padding_1020 { padding: 10px 20px; }
.nicdark_padding_510 { padding: 5px 10px; }
/*bottom*/
.nicdark_padding_botttom_0 { padding-bottom: 0px; }
.nicdark_padding_botttom_5 { padding-bottom: 5px; }
.nicdark_padding_botttom_10 { padding-bottom: 10px; }
/*top*/
.nicdark_padding_top_0 { padding-top: 0px; }
.nicdark_padding_top_10 { padding-top: 10px; }
/*left*/
.nicdark_padding_left_220 { padding-left: 220px; }
.nicdark_padding_left_120 { padding-left: 120px; }
.nicdark_padding_left_100 { padding-left: 100px; }
.nicdark_padding_left_90 { padding-left: 90px; }
.nicdark_padding_left_80 { padding-left: 80px; }
.nicdark_padding_left_70 { padding-left: 70px; }
.nicdark_padding_left_50 { padding-left: 50px; }
.nicdark_padding_left_45 { padding-left: 45px; }
.nicdark_padding_left_40 { padding-left: 40px; }
.nicdark_padding_left_25 { padding-left: 25px; }
.nicdark_padding_left_20 { padding-left: 20px; }
.nicdark_padding_left_10 { padding-left: 10px; }
.nicdark_padding_left_5 { padding-left: 5px; }
.nicdark_padding_left_0 { padding-left: 0px; }
/*right*/
.nicdark_padding_right_0 { padding-right: 0px; }
.nicdark_padding_right_5 { padding-right: 5px; }
.nicdark_padding_right_10 { padding-right: 10px; }
.nicdark_padding_right_20 { padding-right: 20px; }
.nicdark_padding_right_40 { padding-right: 40px; }


/* 7.13 border */
/*border radius*/
.nicdark_border_radius_3 { border-radius: 3px; }
.nicdark_border_radius_0_0_3_3 { border-radius: 0px 0px 3px 3px; }
.nicdark_border_radius_3_3_0_0 { border-radius: 3px 3px 0px 0px; }
.nicdark_border_radius_50 { border-radius: 50px; }
.nicdark_border_radius_100_percentage { border-radius: 100%; }
/*general border*/
.nicdark_border_width_2 { border-width: 2px; }
.nicdark_border_0 { border: 0px solid #fff; }
.nicdark_border_1_solid_white { border: 1px solid #fff; }
.nicdark_border_2_solid_white { border: 2px solid #fff; }
.nicdark_border_2_solid_white_hover:hover { border: 2px solid #fff; }
.nicdark_border_1_solid_grey { border: 1px solid #f1f1f1; }
.nicdark_border_1_solid_grey_2 { border: 1px solid #a3a3a3; }
.nicdark_border_3_solid_white { border: 3px solid #fff; }
.nicdark_border_3_dashed_grey { border: 3px dashed #f1f1f1; }
.nicdark_border_1_solid_green { border: 1px solid #68B78C; }
.nicdark_border_1_solid_green_hover:hover { border: 1px solid #68B78C; }
.nicdark_border_2_solid_green { border: 2px solid #68B78C; }
.nicdark_border_1_solid_orange { border: 1px solid #07034f; }
/*border top*/
.nicdark_border_top_width_0{ border-top-width: 0px; }
.nicdark_border_top_1_solid_grey { border-top: 1px solid #f1f1f1; }
.nicdark_border_top_1_solid_greydark { border-top: 1px solid #595959; }
.nicdark_border_top_2_solid_grey { border-top: 2px solid #f1f1f1; }
/*border bottom*/
.nicdark_border_bottom_width_0{ border-bottom-width: 0px; }
.nicdark_border_bottom_width_2{ border-bottom-width: 2px; }
.nicdark_border_bottom_1_solid_grey { border-bottom: 1px solid #f1f1f1; }
.nicdark_border_bottom_2_solid_grey { border-bottom: 2px solid #f1f1f1; }
.nicdark_border_bottom_2_solid_greendark { border-bottom: 2px solid #64AB84; }
.nicdark_border_bottom_4_solid_white { border-bottom: 4px solid #fff; }
.nicdark_border_bottom_3_solid_white { border-bottom: 3px solid #fff; }
.nicdark_border_bottom_1_solid_greydark { border-bottom: 1px solid #595959; }
/*border left*/
.nicdark_border_left_width_0{ border-left-width: 0px; }
/*border right*/
.nicdark_border_right_width_0{ border-right-width: 0px; }


/* 7.14 box-sizing */
.nicdark_box_sizing_border_box { box-sizing: border-box; }


/* 7.15 list-style */
.nicdark_list_style_none { list-style: none; }


/* 7.16 display */
.nicdark_display_inline { display: inline; }
.nicdark_display_inline_block { display: inline-block; }
.nicdark_display_block { display: block; }
.nicdark_display_none { display: none; }
.nicdark_display_table { display: table; }
.nicdark_display_table_cell { display: table-cell; }

/* 7.17 vertical-align */
.nicdark_vertical_align_middle { vertical-align: middle; }
.nicdark_vertical_align_top { vertical-align: top; }
.nicdark_vertical_align_bottom { vertical-align: bottom; }

/* 7.18 float */
.nicdark_float_left { float: left; }
.nicdark_float_right { float: right; }


/* 7.19 bg size position repeat */
.nicdark_background_size_cover { background-size: cover; }
.nicdark_background_size_25 { background-size: 25px; }
.nicdark_background_position_center { background-position: center center; }
.nicdark_background_position_center_bottom { background-position: center bottom; }
.nicdark_background_position_center_top { background-position: center top; }
.nicdark_background_repeat_no_repeat { background-repeat: no-repeat; }


/* 7.20 position */
.nicdark_position_absolute { position: absolute; }
.nicdark_position_relative { position: relative; }
.nicdark_position_fixed { position: fixed; }


/* 7.21 right left top bottom */
.nicdark_right_300_negative { right: -300px; }
.nicdark_right_0 { right: 0; }
.nicdark_right_20 { right: 20px; }
.nicdark_left_0 { left: 0; }
.nicdark_left_10 { left: 10px; }
.nicdark_left_15 { left: 15px; }
.nicdark_left_65_negative { left: -65px; }
.nicdark_top_0 { top: 0; }
.nicdark_top_5 { top: 5px; }
.nicdark_top_40_negative { top: -40px; }
.nicdark_top_10_negative { top: -10px; }
.nicdark_top_5_negative { top: -5px; }
.nicdark_top_3_negative { top: -3px; }
.nicdark_top_10 { top: 10px; }
.nicdark_top_17 { top: 17px; }
.nicdark_top_20 { top: 20px; }
.nicdark_bottom_20 { bottom: 20px; }
.nicdark_bottom_30 { bottom: 30px; }
.nicdark_bottom_35_negative { bottom: -35px; }


/* 7.22 overflow */
.nicdark_overflow_hidden { overflow: hidden; }
.nicdark_overflow_x_auto { overflow-x: auto; }
.nicdark_overflow_y_auto { overflow-y: auto; }


/* 7.23 cursor */
.nicdark_cursor_move { cursor: move; }
.nicdark_cursor_pointer { cursor: pointer; }
.nicdark_cursor_zoom_out { cursor: zoom-out; }


/* 7.24 outline */
.nicdark_outline_0{ outline: 0; }


/* 7.25 z-index */
.nicdark_z_index_1_negative{ z-index: -1; }
.nicdark_z_index_9{ z-index: 9; }
.nicdark_z_index_99{ z-index: 99; }
.nicdark_z_index_999{ z-index: 999; }


/* 7.26 transition */
.nicdark_transition_all_08_ease{
	-webkit-transition: all 0.8s ease; -moz-transition: all 0.8s ease; -o-transition: all 0.8s ease; -ms-transition: all 0.8s ease; transition: all 0.8s ease;
}

/* 7.27 opacity */
.nicdark_opacity_0{ opacity: 0; }
.nicdark_opacity_07{ opacity: 0.7; }
.nicdark_opacity_05_hover:hover{ opacity: 0.5; }


/* 7.28 zoom */
.nicdark_zoom_1_1_hover:hover{ zoom: 1.1; }




/* 8 CUSTOM FOR THEME ------------------------------------------------------- */

/* 8.1 navigation 1*/
.nicdark_navigation_1 { margin-left: 70px; }
.nicdark_navigation_1 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_1 > ul > li { display: inline-block; }
.nicdark_navigation_1 > ul > li:after { content: "|"; display: inline-block; margin: 0px 20px; color: #bda382; }
.nicdark_navigation_1 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_1 li a { color: #fff; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_1 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_1 > ul li > ul.nicdark_sub_menu { background-color: #B8BB64; position: absolute; margin: 0px; padding: 0px; list-style: none; display: none; }
.nicdark_navigation_1 > ul li > ul.nicdark_sub_menu > li { padding: 5px 10px; font-size: 13px; border-bottom: 1px solid #000; }
.nicdark_navigation_1 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }
/*top header*/
.nicdark_navigation_top_header_1 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_top_header_1 > ul > li { display: inline-block; }
.nicdark_navigation_top_header_1 > ul > li:after { content: "|"; display: inline-block; margin: 0px 20px; font-size: 13px; color:#d8d8d8; }
.nicdark_navigation_top_header_1 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_top_header_1 li a { font-size: 13px; }
.nicdark_navigation_top_header_1 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_top_header_1 > ul li > ul.nicdark_sub_menu { background-color: #B8BB64; position: absolute; margin: 0px; padding: 0px; list-style: none; display: none; }
.nicdark_navigation_top_header_1 > ul li > ul.nicdark_sub_menu > li { padding: 5px 10px; font-size: 13px; border-bottom: 1px solid #000; }
.nicdark_navigation_top_header_1 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }



/* 8.2 navigation 2 */
.nicdark_navigation_2 { margin-left: 70px; }
.nicdark_navigation_2 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_2 > ul > li { display: inline-block; }
.nicdark_navigation_2 > ul > li:after { content: "|"; display: inline-block; margin: 0px 20px; color: #B7B7B7; }
.nicdark_navigation_2 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_2 li a { color: #fff; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_2 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_2 > ul li > ul.nicdark_sub_menu { position: absolute; margin: 0px; padding: 0px; list-style: none; display: none; margin-left: -20px; padding-top: 20px; width: 170px; }
.nicdark_navigation_2 > ul li > ul.nicdark_sub_menu > li { padding: 10px 20px; border-bottom: 1px solid #f1f1f1; text-align: left; background-color: #fff; position: relative; box-shadow: 0px 2px 5px #f1f1f1; }
.nicdark_navigation_2 > ul li > ul.nicdark_sub_menu > li:hover { background-color: #f9f9f9;  }
.nicdark_navigation_2 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }
.nicdark_navigation_2 > ul li > ul.nicdark_sub_menu li a { color: #07034f; font-size: 14px; }
.nicdark_navigation_2 > ul li > ul.nicdark_sub_menu li > ul.nicdark_sub_menu { margin-left: 150px; top: 0; padding-top: 0; padding-left: 20px; }
/*responsive*/
.nicdark_navigation_2_sidebar > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_2_sidebar > ul > li { display: inline-block; }
.nicdark_navigation_2_sidebar li a { color: #fff; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_2_sidebar li > a { padding: 10px 0px; display: inline-block; font-size: 24px; text-transform: lowercase; }
.nicdark_navigation_2_sidebar > ul li > ul.nicdark_sub_menu { margin: 0px; padding: 0px; list-style: none; }
.nicdark_navigation_2_sidebar > ul li > ul.nicdark_sub_menu > li { padding: 10px 20px; text-align: left; }
.nicdark_navigation_2_sidebar > ul li > ul.nicdark_sub_menu li a { color: #fff; font-size: 14px; }
.nicdark_navigation_2_sidebar > ul li > ul.nicdark_sub_menu li > ul.nicdark_sub_menu {  }
/*top header*/
.nicdark_navigation_top_header_2 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_top_header_2 > ul > li { display: inline-block; }
.nicdark_navigation_top_header_2> ul > li:after { content: "|"; display: inline-block; margin: 0px 15px; font-size: 13px; color:#5F5F5F; }
.nicdark_navigation_top_header_2 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_top_header_2 li a { font-size: 13px; }
.nicdark_navigation_top_header_2 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_top_header_2 > ul li > ul.nicdark_sub_menu { padding: 10px 0px 0px 15px; position: absolute; margin: 0px; list-style: none; display: none; z-index: 9; }
.nicdark_navigation_top_header_2 > ul li > ul.nicdark_sub_menu > li { padding: 7px 15px; font-size: 13px; border-bottom: 1px solid #595959; background-color: #444444; }
.nicdark_navigation_top_header_2 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }


/* 8.2 navigation 3 */
.nicdark_navigation_3 { margin-left: 70px; }
.nicdark_navigation_3 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_3 > ul > li { display: inline-block; }
.nicdark_navigation_3 > ul > li:after { content: "|"; display: inline-block; margin: 0px 20px; color: #f1f1f1; }
.nicdark_navigation_3 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_3 li a { color: #07034f; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_3 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_3 > ul li > ul.nicdark_sub_menu { position: absolute; margin: 0px; padding: 0px; list-style: none; display: none; margin-left: -20px; padding-top: 20px; width: 170px; }
.nicdark_navigation_3 > ul li > ul.nicdark_sub_menu > li { padding: 10px 20px; border-bottom: 1px solid #f1f1f1; text-align: left; background-color: #fff; position: relative; box-shadow: 0px 2px 5px #f1f1f1; }
.nicdark_navigation_3 > ul li > ul.nicdark_sub_menu > li:hover { background-color: #f9f9f9;  }
.nicdark_navigation_3 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }
.nicdark_navigation_3 > ul li > ul.nicdark_sub_menu li a { color: #07034f; font-size: 14px; }
.nicdark_navigation_3 > ul li > ul.nicdark_sub_menu li > ul.nicdark_sub_menu { margin-left: 150px; top: 0; padding-top: 0; padding-left: 20px; }
/*responsive*/
.nicdark_navigation_3_sidebar > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_3_sidebar > ul > li { display: inline-block; }
.nicdark_navigation_3_sidebar li a { color: #fff; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_3_sidebar li > a { padding: 10px 0px; display: inline-block; font-size: 24px; text-transform: lowercase; }
.nicdark_navigation_3_sidebar > ul li > ul.nicdark_sub_menu { margin: 0px; padding: 0px; list-style: none; }
.nicdark_navigation_3_sidebar > ul li > ul.nicdark_sub_menu > li { padding: 10px 20px; text-align: left; }
.nicdark_navigation_3_sidebar > ul li > ul.nicdark_sub_menu li a { color: #fff; font-size: 14px; }
.nicdark_navigation_3_sidebar > ul li > ul.nicdark_sub_menu li > ul.nicdark_sub_menu {  }
/*top header*/
.nicdark_navigation_top_header_3 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_top_header_3 > ul > li { display: inline-block; }
.nicdark_navigation_top_header_3> ul > li:after { content: "|"; display: inline-block; margin: 0px 15px; font-size: 13px; color: rgba(255, 255, 255, 0.32); }
.nicdark_navigation_top_header_3 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_top_header_3 li a { font-size: 13px; }
.nicdark_navigation_top_header_3 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_top_header_3 > ul li > ul.nicdark_sub_menu { padding: 10px 0px 0px 15px; position: absolute; margin: 0px; list-style: none; display: none; z-index: 9; }
.nicdark_navigation_top_header_3 > ul li > ul.nicdark_sub_menu > li { padding: 7px 15px; font-size: 13px; border-bottom: 1px solid #595959; background-color: #444444; }
.nicdark_navigation_top_header_3 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }


/* 8.2 navigation 4 */
.nicdark_navigation_4 { margin-left: 70px; }
.nicdark_navigation_4 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_4 > ul > li { display: inline-block; }
.nicdark_navigation_4 > ul > li:after { content: "|"; display: inline-block; margin: 0px 20px; color: #f1f1f1; }
.nicdark_navigation_4 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_4 li a { color: #07034f; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_4 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_4 > ul li > ul.nicdark_sub_menu { position: absolute; margin: 0px; padding: 0px; list-style: none; display: none; margin-left: -20px; padding-top: 20px; width: 170px; }
.nicdark_navigation_4 > ul li > ul.nicdark_sub_menu > li { padding: 10px 20px; border-bottom: 1px solid #f1f1f1; text-align: left; background-color: #fff; position: relative; box-shadow: 0px 2px 5px #f1f1f1; }
.nicdark_navigation_4 > ul li > ul.nicdark_sub_menu > li:hover { background-color: #f9f9f9;  }
.nicdark_navigation_4 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }
.nicdark_navigation_4 > ul li > ul.nicdark_sub_menu li a { color: #07034f; font-size: 14px; }
.nicdark_navigation_4 > ul li > ul.nicdark_sub_menu li > ul.nicdark_sub_menu { margin-left: 150px; top: 0; padding-top: 0; padding-left: 20px; }
/*responsive*/
.nicdark_navigation_4_sidebar > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_4_sidebar > ul > li { display: inline-block; }
.nicdark_navigation_4_sidebar li a { color: #fff; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_4_sidebar li > a { padding: 10px 0px; display: inline-block; font-size: 24px; text-transform: lowercase; }
.nicdark_navigation_4_sidebar > ul li > ul.nicdark_sub_menu { margin: 0px; padding: 0px; list-style: none; }
.nicdark_navigation_4_sidebar > ul li > ul.nicdark_sub_menu > li { padding: 10px 20px; text-align: left; }
.nicdark_navigation_4_sidebar > ul li > ul.nicdark_sub_menu li a { color: #fff; font-size: 14px; }
.nicdark_navigation_4_sidebar > ul li > ul.nicdark_sub_menu li > ul.nicdark_sub_menu {  }
/*top header*/
.nicdark_navigation_top_header_4 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_top_header_4 > ul > li { display: inline-block; }
.nicdark_navigation_top_header_4> ul > li:after { content: "|"; display: inline-block; margin: 0px 15px; font-size: 13px; color:#5F5F5F; }
.nicdark_navigation_top_header_4 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_top_header_4 li a { font-size: 13px; }
.nicdark_navigation_top_header_4 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_top_header_4 > ul li > ul.nicdark_sub_menu { padding: 10px 0px 0px 15px; position: absolute; margin: 0px; list-style: none; display: none; z-index: 9; }
.nicdark_navigation_top_header_4 > ul li > ul.nicdark_sub_menu > li { padding: 7px 15px; font-size: 13px; border-bottom: 1px solid #595959; background-color: #444444; }
.nicdark_navigation_top_header_4 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }


/* 8.2 navigation 5 */
.nicdark_navigation_5 { margin-left: 70px; }
.nicdark_navigation_5 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_5 > ul > li { display: inline-block; }
.nicdark_navigation_5 > ul > li:after { content: "|"; display: inline-block; margin: 0px 20px; color: #B7B7B7; }
.nicdark_navigation_5 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_5 li a { color: #fff; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_5 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_5 > ul li > ul.nicdark_sub_menu { position: absolute; margin: 0px; padding: 0px; list-style: none; display: none; margin-left: -20px; padding-top: 20px; width: 170px; }
.nicdark_navigation_5 > ul li > ul.nicdark_sub_menu > li { padding: 10px 20px; border-bottom: 1px solid #f1f1f1; text-align: left; background-color: #fff; position: relative; box-shadow: 0px 2px 5px #f1f1f1; }
.nicdark_navigation_5 > ul li > ul.nicdark_sub_menu > li:hover { background-color: #f9f9f9;  }
.nicdark_navigation_5 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }
.nicdark_navigation_5 > ul li > ul.nicdark_sub_menu li a { color: #07034f; font-size: 14px; }
.nicdark_navigation_5 > ul li > ul.nicdark_sub_menu li > ul.nicdark_sub_menu { margin-left: 150px; top: 0; padding-top: 0; padding-left: 20px; }
/*responsive*/
.nicdark_navigation_5_sidebar > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_5_sidebar > ul > li { display: inline-block; }
.nicdark_navigation_5_sidebar li a { color: #fff; font-size: 16px; font-family: 'Montserrat'; }
.nicdark_navigation_5_sidebar li > a { padding: 10px 0px; display: inline-block; font-size: 24px; text-transform: lowercase; }
.nicdark_navigation_5_sidebar > ul li > ul.nicdark_sub_menu { margin: 0px; padding: 0px; list-style: none; }
.nicdark_navigation_5_sidebar > ul li > ul.nicdark_sub_menu > li { padding: 10px 20px; text-align: left; }
.nicdark_navigation_5_sidebar > ul li > ul.nicdark_sub_menu li a { color: #fff; font-size: 14px; }
.nicdark_navigation_5_sidebar > ul li > ul.nicdark_sub_menu li > ul.nicdark_sub_menu {  }
/*top header*/
.nicdark_navigation_top_header_5 > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_top_header_5 > ul > li { display: inline-block; }
.nicdark_navigation_top_header_5> ul > li:after { content: "|"; display: inline-block; margin: 0px 15px; font-size: 13px; color:#b9b9b9; }
.nicdark_navigation_top_header_5 > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_top_header_5 li a { font-size: 13px; }
.nicdark_navigation_top_header_5 > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_top_header_5 > ul li > ul.nicdark_sub_menu { padding: 10px 0px 0px 15px; position: absolute; margin: 0px; list-style: none; display: none; z-index: 9; }
.nicdark_navigation_top_header_5 > ul li > ul.nicdark_sub_menu > li { padding: 7px 15px; font-size: 13px; border-bottom: 1px solid #595959; background-color: #444444; }
.nicdark_navigation_top_header_5 > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }


/* 8.6 navigation copyright */
.nicdark_navigation_copyright > ul { list-style: none; margin: 0px; padding: 0px; }
.nicdark_navigation_copyright > ul > li { display: inline-block; }
.nicdark_navigation_copyright > ul > li:after { content: "|"; display: inline-block; margin: 0px 20px; font-size: 14px; color:#5F5F5F; }
.nicdark_navigation_copyright > ul > li:last-child:after { content: ""; margin: 0px; }
.nicdark_navigation_copyright li a { font-size: 14px; }

.nicdark_navigation_copyright > ul li:hover > ul.nicdark_sub_menu { display: block; }
.nicdark_navigation_copyright > ul li > ul.nicdark_sub_menu { background-color: #B8BB64; position: absolute; margin: 0px; padding: 0px; list-style: none; display: none; }
.nicdark_navigation_copyright > ul li > ul.nicdark_sub_menu > li { padding: 5px 10px; font-size: 13px; border-bottom: 1px solid #000; }
.nicdark_navigation_copyright > ul li > ul.nicdark_sub_menu > li:last-child { border-bottom: 0px solid #000; }


/* 9 TYPED PLUGIN ------------------------------------------------------- */


.typed-cursor{opacity: 1;font-size: 50px;color: #fff;font-weight: 100;-webkit-animation: blink 0.7s infinite;-moz-animation: blink 0.7s infinite;-ms-animation: blink 0.7s infinite;-o-animation: blink 0.7s infinite;animation: blink 0.7s infinite;}
@-keyframes blink{0% { opacity:1; }50% { opacity:0; }100% { opacity:1; }}
@-webkit-keyframes blink{0% { opacity:1; }50% { opacity:0; }100% { opacity:1; }}
@-moz-keyframes blink{0% { opacity:1; }50% { opacity:0; }100% { opacity:1; }}
@-ms-keyframes blink{0% { opacity:1; }50% { opacity:0; }100% { opacity:1; }}
@-o-keyframes blink{0% { opacity:1; }50% { opacity:0; }100% { opacity:1; }}
.nicdark_typed { border-bottom: 5px solid #07034f; }


/* 10 JQUEY UI ------------------------------------------------------- */

.nicdark_tooltip:hover span { display: block; opacity: 1; }
.ui-tooltip{ font-family: 'Varela Round', sans-serif; z-index: 99; background-color: #444; border-radius: 3px; padding: 5px 10px; position: absolute; color: #fff; margin: 0px; font-size: 16px; outline: 0; -webkit-appearance: none; border: 0;}
.ui-helper-hidden-accessible { display: none; }

/* 11 RESPONSIVE ------------------------------------------------------- */


/* 11.1 ipad land*/
@media only screen and (min-width: 960px) and (max-width: 1199px) {
    .nicdark_container{width:960px;}
    .grid_1,.grid_2,.grid_3,.grid_4,.grid_5,.grid_6,.grid_7,.grid_8,.grid_9,.grid_10,.grid_11,.grid_12{ width: 100%; }
}

/* 11.2 ipad port*/
@media only screen and (min-width: 768px) and (max-width: 959px) {
    .nicdark_container {width:748px;}
    .nicdark_width_100_percentage_ipad_port { width: 100%; }
    .grid_1,.grid_2,.grid_3,.grid_4,.grid_5,.grid_6,.grid_7,.grid_8,.grid_9,.grid_10,.grid_11,.grid_12{ width: 100%; }
}

/* 11.3 iphone land*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
    body{ -webkit-text-size-adjust:none; }      
    .nicdark_container {width:460px;}
    .grid_1,.grid_2,.grid_3,.grid_4,.grid_5,.grid_6,.grid_7,.grid_8,.grid_9,.grid_10,.grid_11,.grid_12{ width: 100%; }
}

/* 11.4 iphone port*/
@media only screen and (min-width: 320px) and (max-width: 479px){
    body{ -webkit-text-size-adjust:none; }
    .nicdark_container{ width:300px;}
    .nicdark_width_100_percentage_iphone_port { width: 100%; }
    .nicdark_text_align_left_iphone_port { text-align: left; }
    .grid_1,.grid_2,.grid_3,.grid_4,.grid_5,.grid_6,.grid_7,.grid_8,.grid_9,.grid_10,.grid_11,.grid_12{ width: 100%; } 
}

/* 11.5 all responsive*/
@media only screen and (min-width: 320px) and (max-width: 1199px) {
    
    .nicdark_display_none_all_responsive { display: none; }
    .nicdark_padding_0_20_responsive { padding: 0px 20px; }
    .nicdark_display_block_responsive { display: block; }
    .nicdark_width_100_percentage_responsive { width: 100%; }
    .nicdark_width_50_percentage_responsive { width: 50%; }
    .nicdark_text_align_center_responsive { text-align: center; }
    .nicdark_text_align_left_responsive { text-align: left; }
    .nicdark_margin_top_5_responsive { margin-top: 5px; }
    .nicdark_border_top_1_solid_greydark_responsive { border-top: 1px solid #595959; }
    .nicdark_border_top_1_solid_grey_responsive { border-top: 1px solid #f1f1f1; }
    .nicdark_border_top_1_solid_orangedark_responsive { border-top: 1px solid #b59974; }
    .nicdark_cursor_move_responsive { cursor: move; }
    .nicdark_font_size_40_responsive { font-size: 40px; }
    .nicdark_line_height_40_responsive { line-height: 40px; }

}

/* 11.6 all iphone*/
@media only screen and (min-width: 320px) and (max-width: 767px) {
    
    .nicdark_display_none_all_iphone { display: none; }
    .nicdark_display_block_all_iphone { display: block; }
    .nicdark_width_100_percentage_all_iphone { width: 100%; }
    .nicdark_width_50_percentage_all_iphone { width: 50%; }
    .nicdark_width_50_all_iphone { width: 50px; }
    .nicdark_width_250_all_iphone { width: 250px; }
    .nicdark_text_align_center_all_iphone { text-align: center; }
    .nicdark_text_align_left_all_iphone { text-align: left; }
    .nicdark_padding_right_0_all_iphone { padding-right: 0px; }
    .nicdark_padding_left_0_all_iphone { padding-left: 0px; }
    .nicdark_padding_0_all_iphone { padding: 0px; }
    .nicdark_padding_40_all_iphone { padding: 40px; }
    .nicdark_font_size_40_all_iphone { font-size: 40px; }
    .nicdark_font_size_20_all_iphone { font-size: 20px; }
    .nicdark_font_size_10_all_iphone { font-size: 10px; }
    .nicdark_line_height_40_all_iphone { line-height: 40px; }
    .nicdark_line_height_20_all_iphone { line-height: 20px; }
    .nicdark_position_relative_all_iphone { position: relative; }
    .nicdark_margin_top_20_all_iphone { margin-top: 20px; }

}